<template>
  <div class="washroom" element-loading-text="拼命加载中">
    <div class="data-box" v-show="true">
      <div class="left-data" v-show="true">
        <div class="data-top">
          <!-- 男厕数据 -->
          <div class="data-top-box">
            <img src="../assets/img/men.png" />
            <div>
              <div class="b-left">
                <span class="testColor">温度(℃):</span>
                <span class="num state-open">{{ wrData.manTemperature }}</span>
              </div>
              <div class="b-left">
                <span class="testColor">湿度(%):</span>
                <span class="num state-open" style="margin-left:12px"> {{ wrData.manHumidity }}</span>
              </div>
            </div>
          </div>
          <!-- 女厕数据 -->
          <div class="data-top-box">
            <img src="../assets/img/women.png" />
            <div>
              <div class="b-left">
                <span class="testColor">温度(℃):</span>
                <span class="num state-open">{{ wrData.womanTemperature }}</span>
              </div>
              <div class="b-left">
                <span class="testColor">湿度(%):</span>
                <span class="num state-open" style="margin-left:12px">{{ wrData.womanHumidity }}</span>
              </div>
            </div>
          </div>
          <!-- 报警数据 -->
          <div class="data-top-box">
            <img src="../assets/img/warn.png" />
            <div>
              <div class="b-left">
                <span class="testColor">男厕(次):</span>
                <span v-if="wrData.manAlarmCount == 0" class="num state-open">{{ wrData.manAlarmCount }}</span>
                <span v-if="wrData.manAlarmCount > 0" class="num state-warn">{{ wrData.manAlarmCount }}</span>
              </div>
              <div class="b-left">
                <span class="testColor">女厕(次):</span>
                <span v-if="wrData.womanAlarmCount == 0" class="num state-open">{{ wrData.womanAlarmCount }}</span>
                <span v-if="wrData.womanAlarmCount > 0" class="num state-warn">{{ wrData.womanAlarmCount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="data-item data-info" style="flex-shrink: 0">
          <!-- 公厕信息 -->
          <el-row class="topRow">
            <el-col :span="11"
              ><div class="grid-content table-title">{{ wrData.toiletName }}</div></el-col
            >
            <el-col :span="4"><div class="grid-content  name">更新时间：</div></el-col>
            <el-col :span="9"
              ><div class="grid-content  nameVal">{{ wrData.updateTime }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="11" style="display:flex">
              <div style="display:flex">
                <div class="grid-content name">男厕：</div>
                <div class="grid-content  nameVal">
                  <span class="state-close" v-if="wrData.manStatus === 0 && wrData.workStatus=== 1">未开启</span>
                  <span class="state-open" v-if="wrData.manStatus === 1 && wrData.workStatus=== 1">正常运行</span>
                  <span class="state-warn" v-if="wrData.manStatus === 2 && wrData.workStatus=== 1">报警</span>
                  <span class="state-warn" v-if="wrData.workStatus === 2">关闭</span>
                  <span class="state-warn" v-if="wrData.workStatus=== 3">报修</span>
                </div>
              </div>
              <div style="display:flex;padding-left:23px">
                <div class="grid-content name">女厕：</div>
                <div class="grid-content  nameVal">
                  <span class="state-close" v-if="wrData.womanStatus === 0 && wrData.workStatus=== 1">未开启</span>
                  <span class="state-open" v-if="wrData.womanStatus === 1 && wrData.workStatus=== 1">正常运行</span>
                  <span class="state-warn" v-if="wrData.womanStatus === 2 && wrData.workStatus=== 1">报警</span>
                  <span class="state-warn" v-if="wrData.workStatus === 2">关闭</span>
                  <span class="state-warn" v-if="wrData.workStatus=== 3">报修</span>
                </div>
              </div>
            </el-col>

            <el-col :span="4"><div class="grid-content name">服务类型：</div></el-col>
            <el-col :span="7"><div class="grid-content nameVal" :title="wrData.serveName">{{ wrData.serveName }}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="4"><div class="grid-content  name">联系人：</div></el-col>
            <el-col :span="7"><div class="grid-content  nameVal" :title="wrData.staffName">{{ wrData.staffName }}</div></el-col>
            <el-col :span="4"><div class="grid-content  name">联系电话：</div></el-col>
            <el-col :span="7"><div class="grid-content  nameVal" :title="wrData.staffPhone">{{ wrData.staffPhone }}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="4"><div class="grid-content  name">所属项目部：</div></el-col>
            <el-col :span="7" v-if="wrData.projectDeptName"><div class="grid-content  nameVal" :title="wrData.projectDeptName">{{ wrData.projectDeptName }}</div></el-col>
            <el-col :span="7" v-else><div class="grid-content  nameVal" :title="wrData.projectDeptName">/</div></el-col>
            <el-col :span="4"><div class="grid-content name">联系电话：</div></el-col>
            <el-col :span="7"
              ><div class="grid-content  nameVal" :title="wrData.staffPhone">{{ wrData.staffPhone }}</div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="4"><div class="grid-content name">公厕地址：</div></el-col>
            <el-col :span="19"
              ><div class="grid-content  nameVal" :title="wrData.location">{{ wrData.location }}</div></el-col
            >
          </el-row>
        </div>
        <div class="data-item data-warn">
          <el-table :data="washroomAlarmList" height="241" :header-cell-style="tableHeaderColor" :cell-style="rowClass">
            <el-table-column align="center" label="报警类型" prop="typeName" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="报警数值" prop="reason"></el-table-column>
            <el-table-column align="center" label="报警预值" prop="earlyWarningValue"></el-table-column>
            <el-table-column align="center" label="时间" prop="alarmTime" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status == 1"
                  type="danger"
                  size="mini"
                  plain
                  @click="goAlarmcord(scope.row)"
                  >立即处置</el-button
                >
                <el-button
                  v-if="scope.row.status == 2"
                  type="primary"
                  size="mini"
                  plain
                  @click="goAlarmcord(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div style="display:flex">
      <div class="right-data" v-if="true">
        <div class="data-item chart-box">
          <div class="chart-header">
            <div class="c-center">
              <span class="table-title">氨气</span>
              <span class="table-num c-center">
                <span>≤</span>
                <el-input id="input1" @blur="test" v-model="ammoniaGas"></el-input>
                <span>ppm</span>
              </span>
            </div>
            <div class="chart-info">
              <div class="men">
                <img src="../assets/img/won.png" />
                <span style="margin-left:10px">实时数值：</span>
                <span v-if="wrData.manAmmoniaGas < ammoniaGas" class="num state-open">{{ wrData.manAmmoniaGas }}</span>
                <span v-if="wrData.manAmmoniaGas >= ammoniaGas" class="num state-warn">{{ wrData.manAmmoniaGas }}</span>
              </div>
              <div class="women">
                <img src="../assets/img/wonmen.png" />
                <span style="margin-left:10px">实时数值：</span>
                <span v-if="wrData.womanAmmoniaGas < ammoniaGas" class="num state-open">{{ wrData.womanAmmoniaGas }}</span>
                <span v-if="wrData.womanAmmoniaGas >= ammoniaGas" class="num state-warn">{{ wrData.womanAmmoniaGas }}</span>
              </div>
            </div>
            <div>
              <span>日期选择：</span>
              <el-date-picker
                placeholder="请选择日期"
                type="date"
                @change="changeDay($event, 1)"
                value-format="yyyy-MM-dd"
                v-model="selectDate1"
                size="mini"
                :editable="false"
              ></el-date-picker>
            </div>
          </div>
          <div class="chart-item">
            <Echart :option="ChartData1"></Echart>
          </div>
        </div>
        <div class="data-item chart-box">
          <div class="chart-header">
            <div class="c-center">
              <span class="table-title ">硫化氢</span>
              <span class="table-num c-center">
                <span>≤</span>
                <el-input id="input2" @blur="tests" v-model="ydrothion"></el-input>
                <span>ppm</span>
              </span>
            </div>
            <div class="chart-info">
              <div class="men">
                <img src="../assets/img/won.png" />
                <span style="margin-left:10px">实时数值：</span>
                <span v-if="wrData.manYdrothion < ammoniaGas" class="num state-open">{{ wrData.manYdrothion }}</span>
                <span v-if="wrData.manYdrothion >= ammoniaGas" class="num state-warn">{{ wrData.manYdrothion }}</span>
              </div>
              <div class="women">
                <img src="../assets/img/wonmen.png" />
                <span style="margin-left:10px">实时数值：</span>
                <span v-if="wrData.womanYdrothion < ammoniaGas" class="num state-open">{{ wrData.womanYdrothion }}</span>
                <span v-if="wrData.womanYdrothion >= ammoniaGas" class="num state-warn">{{ wrData.womanYdrothion }}</span>
              </div>
            </div>
            <div>
              <span>日期选择：</span>
              <el-date-picker
                placeholder="请选择日期"
                type="date"
                @change="changeDay($event, 2)"
                value-format="yyyy-MM-dd"
                v-model="selectDate2"
                size="mini"
                :editable="false"
              ></el-date-picker>
            </div>
          </div>
          <div class="chart-item">
            <Echart :option="ChartData2"></Echart>
          </div>
        </div>
      </div>
      <div class="video-box" ref="video" v-if="true">
        <div class="videoItem">
          <!-- <div id="video-container0" class="videoitems" ></div>
          <div id="video-container1" class="videoitems" ></div>
          <div id="video-container2" class="videoitems" ></div> -->
          <div  id="video-container0" class="videoitems" ></div>
          <div  id="video-container1" class="videoitems" allowfullscreen></div>
         
          <div  id="video-container2" class="videoitems" allowfullscreen></div>
         
        </div>
       
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from "../request";
import Echart from "./Echart";
import EZUIKit from "ezuikit-js";
import { color } from "echarts";
export default {
  name: "Washroom",
  props: ["name"],
  components: { Echart },
  data() {
    return {
      ammoniaGas: "", //氨气
      ydrothion: "", //硫化氢
      showLoad: true,
      selectDate1: "",
      selectDate2: "",
      tableConfig: {
        height: "100%",
      },
      echartData: {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 40,
          right: 30,
          bottom: 30,
          top: 30,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5, 6, 7],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100,
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "男厕",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },
            color: "#2275FF",
            data: [10, 12, 11, 14, 9, 30, 10, 20, 32, 11, 34, 90, 20, 20, 20, 12, 11, 14, 90, 23, 21, 23, 46, 24],
          },
          {
            name: "女厕",
            type: "line",
            lineStyle: {
              color: "#FF8395",
              width: 3,
            },
            color: "#FF8395",
            data: [10, 12, 11, 14, 9, 30, 10, 20, 32, 11, 34, 90, 20, 20, 20, 12, 11, 14, 90, 23, 21, 23, 46, 24].reverse(),
            markLine: {
              label: {
                show: false,
              },
              animation: false,
              data: [{ yAxis: 75 }],
              symbol: "none",
              silent: true,
              lineStyle: {
                type: "solid",
                width: 3,
                color: "#FF5D31",
              },
            },
          },
        ],
      },
      wrData: {
        id: 2, //公厕id
        code: "02",
        toiletName: "香山路公厕1", //公厕名称
        managementDeptId: 1, //管养单位id
        managementDeptName: "油城环卫", //管养单位
        location: "香三路与香山路交叉路口", //地址
        projectDeptId: 1, //项目部id
        projectDeptName: "黄河项目部", //项目部
        deptStaffName: "马城", //项目部负责人
        deptPhone: "15945754511", //项目部负责人电话
        serveId: 4, //服务id
        serveName: "残疾人", //服务类型
        longitude: "111", //经度
        latitude: "222", //纬度
        womanDeviceAddress: "1", //女厕设备地址码
        manDeviceAddress: "2", //男厕设备地址码
        womanHumidity: "2", //女厕湿度
        womanTemperature: "0", //女厕温度
        womanAmmoniaGas: "0", //女厕氨气
        womanYdrothion: "0", //女厕硫化氢
        womanReserved: "0", //女厕预留
        womanReserved2: "0", //女厕预留2
        manHumidity: "0", //男厕湿度
        manTemperature: "0", //男厕温度
        manAmmoniaGas: "0", //男厕氨气
        manYdrothion: "0", //男厕硫化氢
        manReserved: "0", //男厕预留
        manReserved2: "0", //男厕预留2
        manAlarmCount: 0, //男厕报警次数
        womanAlarmCount: 0, //女厕报警次数
        staffId: 2, // 联系人id
        staffName: "宋发平", //联系人名
        staffPhone: "15945754522", //联系电话
        status: 0, //状态 0未开启 1 正常 2 报警
        createTime: "2021-03-02 15:17:38",
        updateTime: "2021-03-11 16:05:27", //更新时间
      },
      videoList: [],
      washroomAlarmList: [],
      alarmTypeName: {
        "1": "男厕氨气报警",
        "2": "女厕氨气报警",
        "3": "男厕硫化氢报警",
        "4": "女厕硫化氢报警",
        "5": "转运站报警",
      },
      ChartData1: null, //氨气图表数据
      ChartData2: null, //硫化氢图表数据,
      curDay: "",
      player: [1, 2, 3],
      playerList:[]
    };
  },
  methods: {
    tableHeaderColor({ row, rowIndex }) {
      return "height:55px;color: #146CFF;font-size:16px;";
    },
    rowClass({ row, rowIndex }) {
      // console.log({ row, rowIndex });
      return "color: #333333;";
    },
    // 生产图表数据模板
    getChartData() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: 40,
          right: 30,
          bottom: 30,
          top: 30,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5, 6, 7],
          axisTick: {
            show: false,
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 10,
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: 1,
            },
          },
        },
        series: [
          {
            name: "男厕",
            type: "line",
            lineStyle: {
              color: "#2275FF",
              width: 3,
            },
            color: "#2275FF",
            data: [10, 12, 11, 14, 9, 30, 10, 20, 32, 11, 34, 90, 20, 20, 20, 12, 11, 14, 90, 23, 21, 23, 46, 24],
            itemStyle: { normal: { label: { show: true } } },
          },
          {
            name: "女厕",
            type: "line",
            lineStyle: {
              color: "#FF8395",
              width: 3,
            },
            color: "#FF8395",
            data: [10, 12, 11, 14, 9, 30, 10, 20, 32, 11, 34, 90, 20, 20, 20, 12, 11, 14, 90, 23, 21, 23, 46, 24].reverse(),
            markLine: {
              label: {
                color: " #FFB712",
                position: "middle",
                formatter: "5",
                fontSize: "16",
              },
              animation: false,
              data: [{ yAxis: 5 }],
              symbol: "none",
              silent: true,
              lineStyle: {
                type: "dashed",
                width: 2,
                color: "#FFB712",
              },
            },
          },
        ],
        id: 1,
      };
    },
    // 跳转至报警记录
    goAlarmcord(item){
      console.log(item)
      this.$router.push({ name: 'alarmrecord', params: { userId: item.id, status: item.siteType, typeID: item.status } })

    },

    // 获取公厕实时报警信息
    async getWashRoomAlarm() {
      let data = await axios.get(`/alarmRecord/findListByToiletId/${this.id}`);
      console.log(data);
      if (data) {
        this.washroomAlarmList = data.map((item) => {
          item.typeName = this.alarmTypeName[item.type];
          return item;
        });

        this.washroomUnAlarmList = data.disposeLists;
        // console.log(data);
      }
    },
    changeDay(e, type) {
      console.log(e,type)
      if (e) {
        this.getWashRoomChartData(e, this.id, type);
        console.log(this.id)
      } else {
        this.getWashRoomChartData(this.curDay, this.id, type);
      }
    },
    // 获取公厕图表数据;type:1氨气；2硫化氢
    async getWashRoomChartData(time, id, type) {
      console.log(time,id,type)
      const query = {
        params: {
          dateTime: time,
          toiletId: id,
          type,
        },
      };
      console.log(query)
      let data = await axios.get("/toiletRealDay/findToilet/dayReport", query);
      console.log(data);
      if (data) {
        let xLabel = data.manToiletDayReport.map((item) => {
          return item.hour;
        });
        let manValue = data.manToiletDayReport.map((item) => {
          return type === 1 ? item.ammoniaGas : item.ydrothion;
        });
        let womenValue = data.womanToiletDayReport.map((item) => {
          return type === 1 ? item.ammoniaGas : item.ydrothion;
        });
        let selTime = new Date(...time.split("-")).getTime();
        let curTime = new Date(...this.curDay.split("-")).getTime();
        console.log(selTime, curTime);
        if (selTime > curTime) {
          manValue = [];
          womenValue = [];
        }
        if(selTime === curTime){
            let hour = new Date().getHours();
            let index = xLabel.indexOf(hour) + 1;
            console.log(hour)
            manValue.splice(index)
            womenValue.splice(index)
        }
        console.log(manValue)
        let xnumMax = Math.max(...manValue);
        let ynumMax = Math.max(...womenValue);
        let xMax = Math.max(...[xnumMax, ynumMax, this.ammoniaGas]);
        let yMax = Math.max(...[xnumMax, ynumMax, this.ydrothion]);
        console.log(xMax, yMax);
        let chartData = this.getChartData();
        console.log(xnumMax, ynumMax);
        chartData.xAxis.data = xLabel;
        chartData.series[0].data = manValue;
        chartData.series[1].data = womenValue;
        if (type === 1) {
          let xAxis = chartData;
          console.log(xAxis);
          xAxis.series[1].markLine.data[0].yAxis = this.ammoniaGas;
          xAxis.series[1].markLine.label.formatter = this.ammoniaGas + "ppm";
          if (xnumMax> this.ydrothion || ynumMax>this.ydrothion) {
            // splitNumber: 2,
           xAxis.yAxis.max=xMax+1

          } else {
            xAxis.yAxis.max = xMax*1.2
          }
          
          // xAxis.yAxis.max = xMax;
          this.ChartData1 = chartData;
          console.log(this.ChartData1)
        } else {
          let yAxis = chartData;
          console.log(yAxis);
          yAxis.series[1].markLine.data[0].yAxis = this.ydrothion;
          yAxis.series[1].markLine.label.formatter = this.ydrothion + "ppm";
          // splitNumber: 2,
          if (xnumMax> this.ydrothion || ynumMax>this.ydrothion) {
            
            yAxis.yAxis.max = yMax+2;
          } else {
            yAxis.yAxis.max = this.ydrothion * 1.2;
          }
          // yAxis.yAxis.max=yMax*1.2
          //yAxis.yAxis.interval=0.1
          this.ChartData2 = yAxis;
          console.log(chartData);
        }
      }
    },

    async getWashRoomData(id) {
      this.showLoad = true;
      this.id = id;
      const video0 = document.querySelector('#video-container0')
      const video1 = document.querySelector('#video-container1')
      const video2 = document.querySelector('#video-container2')
      if (video0 && video1 &&video2) {
        video0.innerHTML = ''
        video1.innerHTML = ''
        video2.innerHTML = ''
      }
      // 获取公厕信息
      let data = await axios.get(`/toilet/findById/${id}`);
      if (data) this.wrData = data;
      console.log(this.wrData);
      // 视频数据
      if (this.videoList.length > 0) {
        //    this.$router.go(0)
        this.videoList = [];
        //   this.$refs.video.innerHTML = `
        //  <iframe  :src="videoList[0]"
        //   id="video1"
        //   class="videoitems"
        //   allowfullscreen ></iframe>
        //   <iframe  :src="videoList[1]"
        //   id="video2"
        //   class="videoitems"
        //   allowfullscreen ></iframe>
        //   <iframe  :src="videoList[2]"
        //   id="video3"
        //   class="videoitems"
        //   allowfullscreen ></iframe>
        //   <iframe  :src="videoList[3]"
        //   id="video4"
        //   class="videoitems"
        //   allowfullscreen ></iframe>`
      }
      let video = await axios.get(`/video//findByToiletId/${id}`);
      console.log(video);
      if (video) {
        this.playerList = video.map(item =>  {
          return {
            accessToken: item.accessToken,
            url: item.videoAddress
          }
        })
        if (this.playerList.length != 0){
          for (const i in this.player) {
            for (const j in this.playerList) {
              if (i == j) {
                this.player[i] = new this.$ezuikit.EZUIKitPlayer({
                  id: `video-container${i}`, // 视频容器ID
                  templete: 'standard',
                  // header: ['capturePicture', 'save'],
                  footer: ['hd', 'fullScreen'],
                  accessToken: this.playerList[j].accessToken,
                  url: this.playerList[j].url,
                  height: 225
                })
              }
            }
          }
        } else {
          video0.innerHTML = ''
          video1.innerHTML = ''
          video2.innerHTML = ''
        }
       

        //https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/F01996616/1.live&autoplay=1&audio=1&accessToken=at.58mgmz3tdqgkq4r490ub6z02491olq7o-7slsnis7l4-08sjrcm-x5mxwydlj&templete=1
        //https://open.ys7.com/ezopen/h5/iframe?url=ezopen://open.ys7.com/F01996616/1.hd.live&autoplay=1&accessToken=at.58mgmz3tdqgkq4r490ub6z02491olq7o-7slsnis7l4-08sjrcm-x5mxwydlj
        // let player = document.getElementById("video1").contentWindow; /* iframe 支持方法 */
        // player.postMessage("play", url); /* 播放 */
        // player.postMessage("stop", url); /* 结束 */
        // player.postMessage("capturePicture", url); /* 截图 */
        // player.postMessage("openSound", url); /* 开启声音 */
        // player.postMessage("closeSound", url); /* 关闭声音 */
        // player.postMessage("startSave", url); /* 开始录制 */
        // player.postMessage("stopSave", url); /* 结束录制 */
        // video.forEach((item, index) => {
        //     this.videoList[index] = new EZUIKit.EZUIKitPlayer({
        //         id: "video" + (index + 1),
        //         accessToken: item.accessToken,
        //         url: item.videoAddress,
        //         templete: "simple",
        //         audio: 0,
        //         header: ['capturePicture'],
        //         footer: ['hd', 'fullScreen']
        //     })
        // })
      }

      this.getWashRoomAlarm();
      this.getWashRoomChartData(this.curDay, id, 1);
      this.getWashRoomChartData(this.curDay, id, 2);
      this.showLoad = false;
    },
    standard() {
      axios.get(`/toilet/find/standard`).then((res) => {
        if (res) {
          console.log(res);
          this.ammoniaGas = res.ammoniaGas; //氨气
          this.ydrothion = res.ydrothion; //硫化氢
        }
      });
    },
    //氨气修改
    test() {
      axios.post(`/toilet/update/standard/ammoniaGas`, { ammoniaGas: this.ammoniaGas }).then((res) => {
        if (res == null) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getWashRoomData(this.name);
          this.standard();
        }
      });
    },
    //硫化氢修改
    tests() {
      axios.post(`/toilet/update/standard/ydrothion`, { ydrothion: this.ydrothion }).then((res) => {
        if (res == null) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getWashRoomData(this.name);
          this.standard();
        }
      });
    },
  },
  watch: {
    ammoniaGas(val) {
      console.log(val);
      let docInput1 = document.getElementById("input1");
      switch (val.length) {
        case 1:
          docInput1.style = "width:15px";
          break;
        case 2:
          docInput1.style = "width:22px";
          break;
        case 3:
          docInput1.style = "width:32px";
          break;
        case 4:
          docInput1.style = "width:42px";
          break;
      }
    },
    ydrothion(val) {
      let docInput2 = document.getElementById("input2");
      switch (val.length) {
        case 1:
          docInput2.style = "width:15px";
          break;
        case 2:
          docInput2.style = "width:22px";
          break;
        case 3:
          docInput2.style = "width:32px";
          break;
        case 4:
          docInput2.style = "width:42px";
          break;
      }
    },
  },
  mounted() {
    const time = new Date();
    this.curDay = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    this.getWashRoomData(this.name);
    this.standard();
  },
  beforeRouteUpdate(to, from, next) {
    this.getWashRoomData(to.params.name);
    next();
  },
  beforeDestroy() {
    this.videoList.forEach((item) => item.stop());
  },
};
</script>

<style scoped lang="scss">
.washroom {
  flex: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.data-item {
  border-top: solid 3px #2f78ff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-radius: 6px;
  background-color: white;
  flex: auto;
  padding: 0 10px 10px;
  // display: flex;
  // overflow: auto;
}
.table-title {
  color: $blue !important;
  font-size: 1.2rem;
  font-weight: bold;
}
.data-box {
  display: flex;
  // height: 60%;
  // flex: auto;
  .left-data {
    flex: auto;
    display: flex;
    // flex-direction: column;
    width: calc(463 / 1366 * 100vw);
    
    .data-top-box {
      // flex: auto;
      width: 200px;
      display: flex;
      background-color: white;
      padding: 10px 30px;
      margin-top: 10px;
      align-items: center;
      // margin-left: 10px;
      &:first-child {
        margin-top: 0;
      }
      img {
        margin-right: 10px;
      }
      .num {
        font-size: 1.3rem;
        margin-left: 10px;
      }
      .testColor {
        margin-left: 20px;
        color: #778ca2;
      }
    }

    .data-info {
      width: 440px;
      height: 230px;
      margin: 0 15px;
      // overflow: auto;
    }
    .data-warn {
      width: 470px;
      height: 241px;
      overflow: auto;
      overflow-y: hidden;
      padding: 0;
    }
  }
}
.right-data {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  // padding-left: 25px;
  box-sizing: border-box;
  width: calc(100% - (463 / 1366 * 100vw));
  .chart-box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 270px;
    width: 1000px;
    &:first-child {
      margin-bottom: 15px;
    }
    .chart-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 0;
    }
    .chart-item {
      // flex: auto;
      width: 100%;
      height: 100%;
    }
    .chart-info {
      display: flex;
    }
  }
}
.state-close {
  color: gray;
}
.state-open {
  color: #00da24;
}
.state-warn {
  color: #ff5955;
}
.num {
  font-size: 1.3rem;
}
.men {
  color: #2275ff;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.women {
  color: #2275ff;
  font-weight: bold;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.video-box {
  width: 670px;
  padding-top: 20px;
  // height: 700px;
  box-sizing: border-box;
  // height: 30%;
  // overflow: hidden;
}
.videoItem {
  display: flex;
  flex-wrap: wrap;
 
  
}
</style>

<style lang="scss">
.table-head-style {
  color: #2f78ff;
  font-size: 1.2rem;
}
.videoitems {
  // width: 100%;
  // height: 200px;
  background: url("../assets/img/no_video.png") center/45% 60% no-repeat, white;
  flex: auto;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // flex-direction: column;
  // margin-left: 10px;
  &:first-child {
    // margin-left: 0px;
    width: 300px;
    height: 273px;
  }
  &:nth-child(2) {
    width: 300px;
    height: 273px;
    margin-left: 10px;
  }
  &:nth-child(3) {
    width: 100%;
    height: 273px;
    margin-top: 15px;
    // height: 340px;
  }
}
.table-num {
  font-size: 1.2rem;
  margin-left: 10px;
  color: #ff5955;
  border: solid 1px #ff5955;
  border-radius: 5px;
  padding: 0px 5px 0 5px;
}
.table-num .el-input__inner {
  width: 32px;
  border: none;
  font-size: 1.2rem;
  height: 33px;
  color: #ff5955;
  padding: 0;
}

// 按钮
.el-button--danger.is-plain {
  background: white;
}
.el-button--danger.is-plain:focus {
  background: white;
  color: #f56c6c;
}
.el-button--danger.is-plain:hover {
  background: #f56c6c;
  border-color: #f56c6c;
  color: #fff;
}
.el-button--danger.is-plain:active {
  background: white;
  color: #f56c6c;
}
.el-button--primary.is-plain {
  background: white;
}
.el-button--primary.is-plain:focus {
  color: #409eff;
  background: white;
}
.el-button--primary.is-plain:hover {
  color: #fff;
  background: #409eff;
  border-color: #409eff;
}
.el-button--primary.is-plain:active {
  color: #409eff;
  background: white;
  //border-color: #b3d8ff;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  // width: 180px;
  margin-right: 20px;
}
.el-row {
  margin: 10px 0 20px 0;
}
.topRow {
  padding: 8px 0px 15px 0px;
  border-bottom: solid 1px #e9ecf7;
}
.name {
  text-align: left;
  color: #666666;
}
.nameVal {
  color: #333333;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.el-carousel__arrow {
  width: 80px;
  height: 80px;
  font-size: 2.1rem;
  background: rgb(175, 170, 170);
}
</style>
